import React from "react";
import colors from "../../../../shared/_colors.module.scss";
import { motion } from "framer-motion";
import styles from "../../Blueprint.module.scss";

const variants = {
  hidden: { opacity: 0, pathLength: 0 },
  drawSVG: { opacity: 1, pathLength: 1, transition: { duration: 1 } },
  renderImage: { opacity: 0, transition: { duration: 1 } },
};

const AppBlueprint = () => {
  return (
    <motion.svg
      width="100%"
      height="100%"
      viewBox="0 0 960 800"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.svg}
    >
      <motion.rect
        variants={variants}
        x="1.5"
        y="1.5"
        width="957"
        height="797"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="78.5"
        width="645"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="39.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="124.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="167.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="210.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="252.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="294.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="337.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="380.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="421.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="464.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="507.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="550.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="593.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="636.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="15.5"
        y="679.5"
        width="645"
        height="21"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="338.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="82.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="381.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="124.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="423.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="167.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="466.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="209.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="508.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="251.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="550.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="704.5"
        y="119.5"
        width="241"
        height="86"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="704.5"
        y="282.5"
        width="241"
        height="86"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="704.5"
        y="443.5"
        width="241"
        height="86"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="704.5"
        y="606.5"
        width="241"
        height="86"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="294.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.rect
        variants={variants}
        x="593.5"
        y="78.5"
        width="21"
        height="645"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.line
        variants={variants}
        x1="637.5"
        y1="76.9977"
        x2="638.5"
        y2="724.997"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.line
        variants={variants}
        x1="14"
        y1="101.5"
        x2="662"
        y2="101.5"
        stroke={colors.boldBlue}
        stroke-width="3"
      />
      <motion.line
        variants={variants}
        x1="797"
        y1="144.5"
        x2="852"
        y2="144.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="716"
        y1="182.5"
        x2="935"
        y2="182.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="740"
        y1="326.5"
        x2="815"
        y2="326.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="724"
        y1="651.5"
        x2="799"
        y2="651.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="807"
        y1="651.5"
        x2="843"
        y2="651.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="852"
        y1="651.5"
        x2="926"
        y2="651.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="824"
        y1="326.5"
        x2="909"
        y2="326.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
      <motion.line
        variants={variants}
        x1="745"
        y1="488.5"
        x2="905"
        y2="488.5"
        stroke={colors.boldBlue}
        stroke-width="15"
      />
    </motion.svg>
  );
};

export default AppBlueprint;
